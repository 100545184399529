import React, { useEffect, useState, useRef } from 'react';
import logo from '../src/assets/JDLogo.png';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

function App() {
  const [formStatus, setFormStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const recaptchaRef = useRef();

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-fade-in');
          entry.target.classList.add('opacity-100');
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    document.querySelectorAll('.animate-on-scroll').forEach((element) => {
      element.classList.add('opacity-0');
      observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  const scrollToContact = (e) => {
    e.preventDefault();
    const contactSection = document.getElementById('contact');
    contactSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="bg-white shadow-md fixed w-full z-50 transition-all duration-300">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <img src={logo} alt="Joshua Digital" className="h-10 w-10 hover:scale-105 transition-transform duration-300 rounded-full object-cover" />
            </div>
            <div className="hidden md:flex items-center space-x-8">
              <a href="#services" className="text-dark hover:text-primary transition-colors duration-300">Services</a>
              <a href="#process" className="text-dark hover:text-primary transition-colors duration-300">Process</a>
              <a href="#faq" className="text-dark hover:text-primary transition-colors duration-300">FAQ</a>
              <button 
                onClick={scrollToContact}
                className="text-white bg-primary px-4 py-2 rounded-lg hover:bg-opacity-90 transition-all duration-300"
              >
                Contact
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="bg-gradient-to-r from-gradient-start to-gradient-end pt-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center animate-on-scroll">
            <div className="inline-block bg-white/20 text-white px-4 py-2 rounded-full mb-4 backdrop-blur-sm">
              Modern Solutions for Modern Challenges
            </div>
            <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
              Transform Your Business With Custom Software
            </h1>
            <p className="text-xl text-white mb-8 max-w-3xl mx-auto">
              We create tailored digital solutions that streamline your operations, enhance user experience, and drive business growth.
            </p>
            <button 
              onClick={scrollToContact}
              className="bg-white text-primary px-8 py-4 rounded-lg font-semibold hover:bg-gray-100 transition-all duration-300 hover:shadow-xl transform hover:-translate-y-1 group"
            >
              Start Your Project
              <span className="inline-block transition-transform duration-300 group-hover:translate-x-1 ml-2">→</span>
            </button>
            <p className="text-sm text-white/80 mt-4">Quick response guaranteed</p>
          </div>
        </div>
      </div>

      {/* Services Section */}
      <section id="services" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-4 text-black">Our Solutions</h2>
          <p className="text-center text-gray-600 mb-12 max-w-3xl mx-auto">
            Custom-built digital solutions that adapt to your business needs
          </p>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                title: 'Custom Software',
                description: 'Streamline your business processes with tailor-made solutions',
                icon: '💻'
              },
              {
                title: 'Web Applications',
                description: 'Professional websites that represent your business online',
                icon: '🌐'
              },
              {
                title: 'Mobile Apps',
                description: 'Engage your customers with a dedicated mobile experience',
                icon: '📱'
              },
              {
                title: 'AR Solutions',
                description: 'Create unique interactive experiences for your audience',
                icon: '🔮'
              },
              {
                title: 'AI Integration',
                description: 'Enhance your services with smart technology',
                icon: '🤖'
              }
            ].map((service, index) => (
              <div 
                key={index} 
                className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 hover:scale-105 animate-on-scroll group"
              >
                <div className="text-4xl mb-4 transition-all duration-300 group-hover:scale-100">{service.icon}</div>
                <h3 className="text-xl font-bold mb-2 text-black">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section id="process" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-4 text-black">How We Work</h2>
          <p className="text-center text-gray-600 mb-12">A transparent, efficient process to bring your ideas to life</p>
          <div className="grid md:grid-cols-4 gap-8">
            {[
              {
                step: '01',
                title: 'Discovery',
                description: 'We discuss your goals and requirements in detail'
              },
              {
                step: '02',
                title: 'Planning',
                description: 'We create a detailed project roadmap and timeline'
              },
              {
                step: '03',
                title: 'Development',
                description: 'We build your solution with regular updates'
              },
              {
                step: '04',
                title: 'Launch',
                description: 'We deploy and ensure everything runs smoothly'
              }
            ].map((phase, index) => (
              <div key={index} className="group relative bg-gray-50 p-6 rounded-lg transition-all duration-300 hover:shadow-lg">
                <div className="text-primary font-bold text-4xl mb-2 transition-all duration-300 group-hover:scale-110">{phase.step}</div>
                <h3 className="text-xl font-bold mb-2 text-black">{phase.title}</h3>
                <p className="text-gray-600">{phase.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Mid-page CTA */}
      <section className="py-12 bg-gradient-to-r from-gradient-start to-gradient-end">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-2xl font-bold text-white mb-4">Ready to Transform Your Business?</h2>
          <button 
            onClick={scrollToContact}
            className="bg-white text-primary px-8 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-all duration-300 hover:shadow-xl transform hover:-translate-y-1 group"
          >
            Schedule Your Free Consultation
            <span className="inline-block transition-transform duration-300 group-hover:translate-x-1 ml-2">→</span>
          </button>
        </div>
      </section>

      {/* FAQ Section */}
      <section id="faq" className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12 text-black">Common Questions</h2>
          <div className="max-w-3xl mx-auto space-y-6">
            {[
              {
                question: 'What technologies do you use?',
                answer: 'We use modern, reliable technologies including React, Node.js, and Python. For e-commerce solutions, we work with platforms like Shopify and are an official TikTok Shop partner for app integrations. We also build custom solutions from scratch when needed.'
              },
              {
                question: 'How long does a typical project take?',
                answer: "Project timelines vary based on complexity. Most projects take 4-12 weeks. We'll provide a detailed timeline during our initial consultation."
              },
              {
                question: 'Do you provide e-commerce solutions?',
                answer: 'Yes! We offer both platform-based and custom e-commerce solutions. Whether you need a TikTok Shop integration, Shopify store, or a completely custom-built marketplace, we can help you create the perfect solution for your business.'
              },
              {
                question: 'Do you provide ongoing support?',
                answer: 'Yes, we offer maintenance and support to ensure your solution continues to perform optimally after launch.'
              },
              {
                question: 'How do you handle project communication?',
                answer: 'We maintain regular communication through your preferred channel (email, Slack, etc.) with weekly updates and progress reports.'
              }
            ].map((faq, index) => (
              <div key={index} className="bg-white rounded-lg p-6 shadow-lg transition-all duration-300 hover:shadow-xl">
                <h3 className="text-lg font-bold mb-2 text-black">{faq.question}</h3>
                <p className="text-gray-600">{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Optimized Contact Form */}
      <section id="contact" className="bg-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-4 text-black">Start Your Project</h2>
          <p className="text-center text-gray-600 mb-12 max-w-3xl mx-auto">
            Tell us about your project and we'll get back to you within 24 hours
          </p>
          <div className="max-w-3xl mx-auto">
            <div className="bg-gray-50 rounded-lg shadow-lg p-8 animate-on-scroll relative">
              {showCheckmark && (
                <div className="absolute inset-0 flex items-center justify-center bg-white/80 backdrop-blur-sm z-10">
                  <div className="relative">
                    <svg 
                      className="checkmark" 
                      xmlns="http://www.w3.org/2000/svg" 
                      viewBox="0 0 52 52"
                    >
                      <circle 
                        className="checkmark__circle" 
                        cx="26" 
                        cy="26" 
                        r="25" 
                        fill="#22c55e"
                        style={{
                          strokeDasharray: '166',
                          strokeDashoffset: '166',
                          stroke: '#22c55e',
                          strokeWidth: '2',
                          strokeMiterlimit: '10',
                          animation: 'stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards'
                        }}
                      />
                      <path
                        className="checkmark__check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        style={{
                          stroke: '#fff',
                          strokeWidth: '3',
                          strokeLinecap: 'round',
                          strokeLinejoin: 'round',
                          strokeDasharray: '48',
                          strokeDashoffset: '48',
                          animation: 'stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards'
                        }}
                      />
                    </svg>
                  </div>
                </div>
              )}
              <form className="space-y-6" onSubmit={async (e) => {
                e.preventDefault();
                setIsSubmitting(true);
                setFormStatus('');

                try {
                  const formData = {
                    from_name: e.target.elements.name.value,
                    from_email: e.target.elements.email.value,
                    message: e.target.elements.message.value,
                    to_email: 'info@joshua-digital.com',
                    'g-recaptcha-response': recaptchaValue
                  };

                  await emailjs.send(
                    process.env.REACT_APP_EMAILJS_SERVICE_ID,
                    process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                    formData,
                    process.env.REACT_APP_EMAILJS_PUBLIC_KEY
                  );

                  setShowCheckmark(true);
                  setFormStatus('Your message has been sent! We will get back to you as soon as possible.');
                  e.target.reset();
                  recaptchaRef.current.reset();
                  setRecaptchaValue(null);
                } catch (error) {
                  setFormStatus('Error sending message. Please try again later.');
                  console.error('Error:', error);
                } finally {
                  setIsSubmitting(false);
                }
                return false; // Prevent form submission
              }}>
                <div className="grid md:grid-cols-2 gap-6">
                  <div className="transform transition-all duration-300 hover:translate-x-2">
                    <label className="block text-sm font-medium text-gray-700">Name</label>
                    <input 
                      type="text"
                      name="name"
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-primary focus:ring-primary transition-all duration-300" 
                      required
                      disabled={isSubmitting}
                    />
                  </div>
                  <div className="transform transition-all duration-300 hover:translate-x-2">
                    <label className="block text-sm font-medium text-gray-700">Email</label>
                    <input 
                      type="email"
                      name="email"
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-primary focus:ring-primary transition-all duration-300" 
                      required
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
                <div className="transform transition-all duration-300 hover:translate-x-2">
                  <label className="block text-sm font-medium text-gray-700">Project Description</label>
                  <textarea 
                    name="message"
                    rows="4" 
                    className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-primary focus:ring-primary transition-all duration-300" 
                    placeholder="Tell us about your project goals"
                    required
                    disabled={isSubmitting}
                  ></textarea>
                </div>
                {formStatus && (
                  <div className={`p-4 rounded-lg text-center ${formStatus.includes('succesvol') ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                    {formStatus}
                  </div>
                )}
                <div className="flex w-full">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={(value) => setRecaptchaValue(value)}
                    hl="en"
                    className="transform-none"
                  />
                </div>
                <button 
                  type="submit" 
                  disabled={isSubmitting || !recaptchaValue}
                  className={`w-full bg-primary text-white px-6 py-3 rounded-lg font-semibold transition-all duration-300 transform hover:-translate-y-1 hover:shadow-xl group ${(isSubmitting || !recaptchaValue) ? 'opacity-70 cursor-not-allowed' : 'hover:bg-opacity-90'}`}
                >
                  {isSubmitting ? (
                    <span className="flex items-center justify-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Sending message...
                    </span>
                  ) : (
                    <>
                      Send Message
                      <span className="inline-block transition-transform duration-300 group-hover:translate-x-1 ml-2">→</span>
                    </>
                  )}
                </button>
                <p className="text-sm text-gray-500 text-center mt-4">
                  Usually responds in less than 24 hours
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gradient-to-r from-gradient-start to-gradient-end text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-3 gap-8">
            <div className="animate-on-scroll">
              <h3 className="text-xl font-bold mb-4">Joshua Digital</h3>
              <p className="text-white/80">Building the future of digital solutions</p>
            </div>
            <div className="animate-on-scroll">
              <h3 className="text-xl font-bold mb-4">Contact</h3>
              <p className="text-white/80">Email: info@joshua-digital.com</p>
            </div>
            <div className="animate-on-scroll">
              <h3 className="text-xl font-bold mb-4">Follow Us</h3>
              <div className="flex space-x-4">
                <a href="https://www.linkedin.com/in/joshua-silva-maniche/" className="text-white/80 hover:text-white transition-colors duration-300">LinkedIn</a>
              </div>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-white/20 text-center text-white/80">
            <p>&copy; {new Date().getFullYear()} Joshua Digital. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
